<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div :class="'site-header NotPrintableArea'">
    <b-navbar toggleable="lg" class="navbar navbar-expand-lg  navbar-pis" type="navbar-pis" variant="navbar-pis">
      <b-navbar-brand :href="portalUrl">
        <div class="media logo-media d-flex align-items-center">
          <img src="../assets/img/grb_60x120-2.png" alt="pis logo" class="logo-media__img" />
          <div class="media-body logo-media-body align-self-end">
            <h4 class=" logo-media__title">Правно информациони систем</h4>
            <p class=" logo-media__sub-title">Републике Србије</p>
          </div>
        </div>
      </b-navbar-brand>

      <div class="text-right">
        <b-navbar-toggle target="nav-collapse">
          <span class="icon-menu"></span>
        </b-navbar-toggle>
        <div class="d-flex d-lg-none align-items-center ">
          <div v-if="!userInfoState">
            <b-button variant="primary" v-b-modal.login-modal class="btn btn-primary text-uppercase btn-sm">
              <span class="icon-log-in mr-2"></span>
              Пријава
            </b-button>
          </div>
          <div v-else>
            <div class="btn-group-1-2">
              <div class="div1">
                <button @click="handleLogOut" class="btn d-block btn-sm   btn-primary text-uppercase ">
                  <span class="icon-log-in mr-2"></span>
                  <span class="in-btn-text">Одјава</span>
                </button>
              </div>
              <div class="div2">
                <router-link to="/user" class="btn d-block btn-sm  btn-link ">
                  <span class="icon-user mr-2"></span>
                </router-link>
              </div>
              <div class="div3">
                <router-link to="/reset-pass" class="btn d-block btn-sm btn-link ">
                  <span class="icon-log-out mr-2"></span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-collapse id="nav-collapse" is-nav :visible="shouldCollapse">
        <b-navbar-nav>
          <b-nav-item :href="portalUrl">Почетна</b-nav-item>
          <b-nav-item-dropdown left>
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content>
              Новости
            </template>
            <b-dropdown-item :href="portalUrl + 'news-cat/1/0'">Службени гласник РС</b-dropdown-item>
            <b-dropdown-item :href="portalUrl + 'news-cat/2/0'">Активности државних органа</b-dropdown-item>
            <b-dropdown-item :href="portalUrl + 'news-cat/3/0'">Активности у правосуђу</b-dropdown-item>
            <b-dropdown-item :href="portalUrl + 'news-cat/4/0'">Остало</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item :href="portalUrl + 'news-multimedia/0'">Мултимедија</b-dropdown-item>
            <b-dropdown-item :href="portalUrl + 'news-search'">Претрага</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown left>
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content>
              Електронске базе
            </template>
            <h6 class="dropdown-header">Службени гласник Републике Србије</h6>
            <div class="dropdown-items-box">
              <b-dropdown-item :href="portalUrl + 'slglrsNP-overview/all'">Службени гласник РС</b-dropdown-item>
              <b-dropdown-item :href="portalUrl + 'slglrsMUTG-overview/all'">Службени гласник РС - Међународни уговори
              </b-dropdown-item>
              <b-dropdown-item :href="portalUrl + 'slglrsPG2010-overview/all'">Службени гласник РС - Просветни гласник
              </b-dropdown-item>
            </div>
            <b-dropdown-divider></b-dropdown-divider>
            <h6 class="dropdown-header">Архива службених гласила</h6>
            <div class="dropdown-items-box">
              <b-dropdown-item :href="portalUrl + 'arhslgl-sgarh'">Архива Службеног гласника</b-dropdown-item>
              <b-dropdown-item :href="portalUrl + 'arhslgl-pgarh'">Архива Просветног гласника</b-dropdown-item>
              <b-dropdown-item :href="portalUrl + 'arhslgl-slarh'">Архива Службеног листа</b-dropdown-item>
              <b-dropdown-item :href="portalUrl + 'arhslgl-muarh'">Архива ратификованих међународних уговора и других
                аката
              </b-dropdown-item>
            </div>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item :href="portalUrl + 'reg-overview'">Регистар и текстови важећих прописа и других аката
            </b-dropdown-item>
            <b-dropdown-item :href="portalUrl + 'supa-standardSearch'">Судска пракса</b-dropdown-item>
            <b-dropdown-item :href="enURL" target="_blank">Прописи на енглеском језику
            </b-dropdown-item>
            <b-dropdown-item :href="portalUrl + 'mml-standard-search'">Мишљења, модели, литература</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <h6 class="dropdown-header">Посебни програмски пакети</h6>
            <div class="dropdown-items-box">
              <b-dropdown-item :href="portalUrl + 'pp-content/WPP_ESZLJP_GUIDE'">Водич кроз пресуде Европског суда за људска права
              </b-dropdown-item>
              <b-dropdown-item :href="portalUrl + 'pp-content/WPP_KD'">Кривична дела у судској пракси
              </b-dropdown-item>
              <b-dropdown-item :href="portalUrl + 'regulations-overview'"
              >Прописи градова
            </b-dropdown-item>
            </div>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown left>
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content>
              Водич
            </template>
            <b-dropdown-item :href="portalUrl + 'guide/basic-info'">Шта је Правно-информациони систем?</b-dropdown-item>
            <b-dropdown-item :href="portalUrl + 'guide/how-to'">Упутство за коришћење</b-dropdown-item>
            <b-dropdown-item :href="portalUrl + 'guide/customer-service'">Техничка подршка</b-dropdown-item>
            <b-dropdown-item :href="portalUrl + 'guide/qa'">Питања и одговори</b-dropdown-item>
            <b-dropdown-item :href="portalUrl + 'guide/other'">Остало</b-dropdown-item>
          </b-nav-item-dropdown>

          <!--                     <b-nav-item to="/fp/izbori"><a style="color:#429ddc;">Избори 2020</a></b-nav-item>-->
          <b-nav-item
            href="https://seminari.slglasnik.com/"
            target="_blank"><a style="color:#429ddc;">СЕМИНАРИ И ОБУКЕ</a></b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <div class="d-flex align-items-center">
            <a href="http://www.slglasnik.com/" target="_blank"
              class="mb-2 mb-lg-0 d-none d-lg-inline-block header-glasnik__link">
              <img src="../assets/img/sluzbeni-glasnik-logo-white-bg-89.png" alt="Glasnik logo" />
            </a>
          </div>
          <span class="d-none d-lg-block header-spacer-2"></span>
          <div class="d-none d-lg-flex align-items-center pt-2 pt-lg-0">
            <div v-if="!userInfoState">
              <b-button variant="primary" v-b-modal.login-modal class="btn btn-primary text-uppercase">
                <span class="icon-log-in mr-2"></span>
                Пријава
              </b-button>
            </div>
            <div v-else>
              <router-link to="/user" class="btn d-block d-lg-inline-block mb-2 mb-lg-0  btn-sm btn-link ">
                <span class="icon-user mr-2"></span>
                <span class="btn-text-lg">
                  Мој налог
                </span>
              </router-link>
              <!-- <router-link to="/reset-pass" class="btn d-block d-lg-inline-block mb-2 mb-lg-0  btn-sm btn-link mr-2">
                <span class="icon-log-out mr-2"></span>
                <span class="in-btn-text btn-text-lg">Промена лозинке</span>
              </router-link> -->
              <button @click="handleLogOut"
                class="btn d-block d-lg-inline-block mb-2 mb-lg-0  btn-primary text-uppercase">
                <span class="icon-log-in mr-2"></span>
                <span class="in-btn-text">Одјава
                  <span class="btn-text-lg">{{ userInfoState.name }} {{ userInfoState.lastName }}</span></span>
              </button>
            </div>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div id="nav-icon0">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
import { env } from "@/env";

export default {
  name: "HeaderPartial",
  data() {
    return {
      baseApiUrl: env.VUE_APP_API_PENG_DEV,
      enURL: env.PENG_BASE_URL,
      portalUrl: env.VUE_APP_BASE_URL_PORTAL,
      shouldCollapse: window.innerWidth < 991,
    };
  },
  computed: {
    ...mapState(["userInfoState"]),
    
  },
  methods: {
    ...mapActions([
      'setUserInfo'
    ]),
    handleLogOut() {
      axios.post(this.baseApiUrl + "prins/logout").then((response) => {
        if (this.$route.path === '/user')
          this.$router.push('/');
        this.setUserInfo();
        this.$root.$emit('userLoggedOut');
      });
    },
    handleResize() {
      this.shouldCollapse = window.innerWidth < 991;
    },
  },
  mounted: function () {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style></style>
